<template>
    <section>
        <modal ref="modalDetalleProducto" :titulo="producto.nombre" no-aceptar no-cancelar tamano="modal-lg">
            <div class="row justify-center">
                <div class="col-auto">
                    <img :src="producto.imagen" height="140" width="140" class="border br-10 cr-pointer obj-cover" @click="getImages()" />
                </div>
                <div class="col-4 text-general">
                    <div class="row mx-0 my-2 f-600 f-14">
                        {{ separadorNumero(producto.precio) }}
                    </div>
                    <div class="row mx-0 my-2 mt-3 f-14">
                        <div class="col-10 px-0">
                            {{ producto.descripcion }}
                        </div>
                    </div>
                    <div class="row mx-0 my-2 f-15">
                        <b class="mr-2">
                            Presentación:
                        </b>
                        <p>{{ producto.presentacion }}</p>
                    </div>
                    <div class="row mx-0 my-2 f-15">
                        <b class="mr-2">
                            Unidad de medida:
                        </b>
                        <p>{{ producto.unidad }}</p>
                    </div>
                    <div class="row mx-0 my-2 f-15">
                        <b class="mr-2">
                            Venta mínima:
                        </b>
                        <p>{{ producto.cantidad_minima }} {{ producto.unidad }}.</p>
                    </div>
                </div>
                <div v-show="producto.kit" class="col">
                    <productos-kits ref="detalleKit" />
                </div>
            </div>
        </modal>
        <modal-imagenes ref="modalImagnes" @cerrar="$refs.modalDetalleProducto.toggle()" />
    </section>
</template>

<script>
import Productos from '~/services/productos/productos'
export default {
    data(){
        return {
            estado: '',
            producto:{nombre:''},
            datos:{},
        }
    },
    computed:{
        impuesto(){
            const i = this.producto.porcentaje_impuesto
            return i ? i : 0
        }
    },
    methods: {
        async datos_cambio(producto){
            try {
                console.log(producto);
                this.datos = producto


                this.producto = {
                    id:producto.id_producto ? producto.id_producto : producto.id,
                    nombre:producto.nombre,
                    descripcion:producto.descripcion,
                    imagen:producto.imagen,
                    presentacion:producto.presentacion,
                    unidad:producto.unidad,
                    cantidad_minima:this.agregarSeparadoresNumero(producto.cantidad_minima,2),
                    kit:producto.kit,
                    porcentaje_impuesto:producto.porcentaje_impuesto
                    // cantidad_pedida: this.agregarSeparadoresNumero(cantidad,2) + producto.unidad + ': ' + this.separadorNumero(total_final)

                }

                let precio = producto.id_producto ? this.funValorEnpedido(producto.precio) : this.funValor(producto.precio)
                this.producto.precio = precio

                if(this.producto.kit){
                    await this.$refs.detalleKit.getProductosKit(this.producto.id)
                }
                this.$refs.modalDetalleProducto.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        funValorEnpedido(value){
            if(this.$tienda.tipo_impuesto === 0){
                return value
            }
            if(this.$tienda.tipo_impuesto === 1){
                return value
            }
            if(this.$tienda.tipo_impuesto === 2 || this.$tienda.tipo_impuesto === 3){
                return value * (1 + this.impuesto / 100)
            }
            return 0
        },
        funValor(value){
            console.log('aca');
            if(this.$tienda.tipo_impuesto === 0){
                return value
            }
            if(this.$tienda.tipo_impuesto === 1){
                console.log('aca2',value);
                return value / (1 + this.impuesto / 100)
            }
            if(this.$tienda.tipo_impuesto === 2 || this.$tienda.tipo_impuesto === 3){
                return value
            }
            return 0
        },
        async getImages(){

            const {data} = await Productos.getImages(this.producto.id)
            if(data.imagenes.length === 0) return
            this.$refs.modalDetalleProducto.toggle()
            this.$refs.modalImagnes.toggle(data.imagenes)
        },
    }
}
</script>
